import React, {Component} from "react";
import { FiSend } from "react-icons/fi";
import locales from "../../locales";

class Section61 extends Component {
    render() {
        const title = locales.t('section-61.title');
        const descriptions = [
            locales.t('section-61.desc-1'),
            locales.t('section-61.desc-2'),
            locales.t('section-61.desc-3'),
        ];

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/sections/61-1.png" alt="КОМПЛЕКСНЫЕ И БЕЗОПАСНЫЕ ЗАНЯТИЯ БЕЗ ПОСТОРОННЕЙ ПОМОЩИ"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiSend />
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        {descriptions.map((description, index) => <p key={index} className="description">{description}</p>)}
                                        <div className="purchase-btn">
                                            <a
                                                className="btn-transparent"
                                                href={locales.t('pdf-link')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {locales.t('download-pdf')}
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section61;