import React, {Component} from "react";
import {FiLayers} from "react-icons/all";
import locales from "../../locales";

class Section6 extends Component {
    render() {
        const title = locales.t('section-6.title');

        const items = [
            {
                img: '/assets/images/sections/laptop.webp',
                title: locales.t('section-6.item-1-title'),
                description: locales.t('section-6.item-1-desc'),
            },
            {
                img: '/assets/images/sections/vr.webp',
                title: locales.t('section-6.item-2-title'),
                description: locales.t('section-6.item-2-desc'),
            },
        ]

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <FiLayers/>
                                        </div>
                                        <h2 className="title">{title}</h2>

                                        <div className="creative-portfolio-wrapper ptb--120 bg_color--1">
                                            <div className="container plr--10">
                                                <div className="row mt--30 align-items-stretch platform-image">
                                                    {items.map((item , i ) => (
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 platform-image__item" key={i}>
                                                            <div className="im_portfolio h-100">
                                                                <div className="thumbnail_inner h-100">
                                                                    <div className="thumbnail  h-100">
                                                                        <img
                                                                            src={item.img}
                                                                            alt="React Creative Agency"/>
                                                                    </div>
                                                                </div>
                                                                <div className="content">
                                                                    <div className="inner">
                                                                        <div className="portfolio_heading">
                                                                            <h4 className="title">
                                                                                {item.title}
                                                                            </h4></div>
                                                                        <div className="portfolio_hover">
                                                                            <p>{item.description}</p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="purchase-btn">
                                            <a
                                                className="btn-transparent"
                                                href={locales.t('pdf-link')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {locales.t('download-pdf')}
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Section6;